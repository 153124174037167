'use client';

import type {
    KeyValue,
    RootLayoutCampaignFragment,
    RootLayoutQuery,
    RootLayoutSiteinfoFragment,
    RootLayoutWishlistFragment,
} from '@server/gql/graphql';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { Dictionary } from 'types';

import { createDictionary } from '@/utils/common';

type SiteInfoContextProps = {
    dictionary: Dictionary;
    wishlist?: RootLayoutWishlistFragment | null;
    campaigns?: (RootLayoutCampaignFragment | null)[];
    campaignPriorityCollection?: RootLayoutSiteinfoFragment['campaignPriorityCollection'];
    sideMenuMyPages?: RootLayoutSiteinfoFragment['sideMenuMyPages'];
    myPagesRegisterPage?: RootLayoutSiteinfoFragment['myPagesRegisterPage'];
    myPagesStartPage?: RootLayoutSiteinfoFragment['myPagesStartPage'];
    sideMenuServicePages?: RootLayoutSiteinfoFragment['sideMenuServicePages'];
};

export const SiteInfoContext = createContext<SiteInfoContextProps | undefined>(
    undefined,
);

type SiteInfoProviderProps = PropsWithChildren<{
    siteinfoCollection?: RootLayoutQuery['siteinfoCollection'];
    campaigns?: RootLayoutQuery['campaigns'];
    wishlist?: RootLayoutQuery['wishlist'];
}>;

export const SiteInfoProvider = ({
    siteinfoCollection,
    wishlist,
    campaigns,
    children,
}: SiteInfoProviderProps) => {
    const globalDictionary = createDictionary(
        siteinfoCollection?.items[0]?.globalDictionary?.listCollection
            ?.items as KeyValue[],
    );

    const value = useMemo(() => {
        return {
            dictionary: globalDictionary,
            wishlist: wishlist?.items[0],
            campaigns: campaigns?.items,
            campaignPriorityCollection:
                siteinfoCollection?.items[0]?.campaignPriorityCollection,
            sideMenuMyPages: siteinfoCollection?.items[0]?.sideMenuMyPages,
            myPagesRegisterPage:
                siteinfoCollection?.items[0]?.myPagesRegisterPage,
            myPagesStartPage: siteinfoCollection?.items[0]?.myPagesStartPage,
            sideMenuServicePages:
                siteinfoCollection?.items[0]?.sideMenuServicePages,
        };
    }, [
        globalDictionary,
        wishlist?.items,
        campaigns?.items,
        siteinfoCollection,
    ]);
    return (
        <SiteInfoContext.Provider value={value}>
            {children}
        </SiteInfoContext.Provider>
    );
};

export const useSiteInfo = () => {
    const context = useContext(SiteInfoContext);

    if (context === undefined) {
        throw new Error('useSiteinfo must be used within a SiteInfoProvider');
    }

    return context;
};
