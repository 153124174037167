'use client';

import { Heading } from '@components/ui';
import { useSiteInfo } from '@context/siteInfoContext';
import { Button } from '@ngg/components';
import { ArrowLeftIcon } from '@ngg/icons';
import Link from 'next/link';

/**
 * Handle runtime errors
 *
 * @url https://nextjs.org/docs/app/building-your-application/routing/error-handling
 */

export default function Error({ error }: { error: Error }) {
    console.error('Site error!', error);
    const { dictionary: globalDictionary } = useSiteInfo();

    return (
        <article className="container flex flex-col gap-y-10 p-4 lg:gap-y-20 lg:p-6">
            <header className="flex flex-col-reverse items-start gap-y-10">
                <Heading
                    as="h1"
                    size={{
                        initial: 3,
                        lg: 2,
                    }}>
                    {globalDictionary?.unexpectedError}
                </Heading>
                <Button
                    startIcon={<ArrowLeftIcon className="h-6 w-6" />}
                    className="transform-none bg-transparent p-0 text-sm font-normal tracking-wide text-black underline underline-offset-2"
                    as={Link}
                    href="/eu">
                    {globalDictionary?.goToHomepage}
                </Button>
            </header>
        </article>
    );
}
